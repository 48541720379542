var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"#332f2e","extended":""}},[(_vm.$vuetify.breakpoint.mobile)?[_c('v-app-bar-nav-icon',{staticClass:"app-bar-nav-icon",attrs:{"color":"#cebf9c"},on:{"click":function($event){$event.stopPropagation();_vm.navDrawer = !_vm.navDrawer}}})]:_vm._e(),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.$vuetify.breakpoint.mobile)?[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"shrink mt-6",attrs:{"contain":"","src":require("./assets/morgan-and-mike-logo.png"),"width":"250"}})],1)]:[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"shrink mt-6",attrs:{"contain":"","src":require("./assets/morgan-and-mike-logo.png"),"width":"250"}})],1)],1),_c('v-col',{staticClass:"ml-1 ml-lg-12 mr-1 mr-lg-12",attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},_vm._l((_vm.$router
                  .getRoutes()
                  .filter(
                    function (route) { return route.name !== undefined && route.name !== 'Home'; }
                  )),function(link){return _c('NavigationHeader',{key:link.name,staticClass:"ml-2 ml-lg-4 mr-2 mr-lg-4 mt-4",attrs:{"link":link}})}),1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('Countdown')],1)]],2)],1),(_vm.$vuetify.breakpoint.mobile)?[_c('v-app-bar-nav-icon',{staticStyle:{"visibility":"hidden"}})]:_vm._e()],2),_c('v-navigation-drawer',{staticClass:"nav-drawer-background",attrs:{"temporary":"","fixed":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('Countdown',{staticClass:"pb-6"})]},proxy:true}]),model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',_vm._l((_vm.$router
            .getRoutes()
            .filter(function (route) { return route.name !== undefined; })),function(link){return _c('router-link',{key:link.name,attrs:{"to":link.path.length !== 0 ? link.path : '/'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('v-list-item',{on:{"click":navigate}},[_c('v-list-item-title',{staticClass:"nav-link"},[_vm._v(" "+_vm._s(link.name)+" "),(link.name === 'Live Stream')?[(_vm.hasLiveStreamURL)?[_c('svg',{staticClass:"badge",attrs:{"height":"20","width":"20"}},[_c('circle',{attrs:{"cx":"5","cy":"5","r":"5","fill":"red"}})])]:_vm._e()]:_vm._e()],2)],1)]}}],null,true)})}),1)],1)],1),_c('v-main',[_c('router-view')],1),_c('v-footer',{attrs:{"padless":""}},[_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }