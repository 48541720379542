


































import Vue from "vue";

export default Vue.extend({
  name: "Countdown",
  data: () => ({
    timeRemaining: {
      days: 0,
      hours: 0,
    },
  }),
  created() {
    this.updateRemainingTime();
  },
  methods: {
    updateRemainingTime() {
      const weddingEpoch = 1635087600000; // October 24th, 2021 at 10:00 AM GMT-05:00
      const now = Date.now();
      let remainingMilli = Math.max(0, weddingEpoch - now);

      this.timeRemaining.days = Math.floor(
        remainingMilli / (24 * 60 * 60 * 1000)
      );
      remainingMilli -= this.timeRemaining.days * (24 * 60 * 60 * 1000);

      this.timeRemaining.hours = Math.floor(remainingMilli / (60 * 60 * 1000));
      remainingMilli -= this.timeRemaining.hours * (60 * 60 * 1000);

      setTimeout(() => {
        this.updateRemainingTime();
      }, 60 * 1000);
    },
    formatValue(value: number) {
      return value.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    formatValueDescriptor(value: number, baseDescriptor: string) {
      if (value === 1) {
        return baseDescriptor;
      }

      return baseDescriptor + "S";
    },
  },
});
