


















import Vue from "vue";

export default Vue.extend({
  name: "NavigationHeader",
  props: ["link"],
  computed: {
    hasLiveStreamURL() {
      return (
        process.env.VUE_APP_LIVE_STREAM_URL !== undefined &&
        process.env.VUE_APP_LIVE_STREAM_URL.length > 0
      );
    },
  },
});
