import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "md", // This is equivalent to a value of 1264
  },
  icons: {
    iconfont: "mdiSvg",
  },
});
