import Vue, { Component, CreateElement, RenderContext } from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { Position } from "vue-router/types/router";
import LoadingComponent from "@/components/misc/LoadingComponent.vue";

Vue.use(VueRouter);

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')

function lazyLoadView(component: unknown) {
  const AsyncHandler = () => ({
    component,
    loading: LoadingComponent,
    // Delay before showing the loading component. Default: 200ms.
    delay: 200,
  });

  return Promise.resolve({
    functional: true,
    render(h: CreateElement, { data, children }: RenderContext) {
      return h(AsyncHandler as Component, data, children);
    },
  });
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      lazyLoadView(import(/* webpackChunkName: "home" */ "../views/Home.vue")),
  },
  {
    path: "/rsvp",
    name: "RSVP",
    component: () =>
      lazyLoadView(import(/* webpackChunkName: "rsvp" */ "../views/RSVP.vue")),
  },
  {
    path: "/hotels",
    name: "Hotels",
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "hotels" */ "../views/Hotels.vue")
      ),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () =>
      lazyLoadView(import(/* webpackChunkName: "faq" */ "../views/FAQ.vue")),
    props: true,
  },
  {
    path: "/weddingparty",
    name: "Wedding Party",
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: "weddingparty" */ "../views/WeddingParty.vue"
        )
      ),
  },
  {
    path: "/registry",
    name: "Registry",
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "registry" */ "../views/Registry.vue")
      ),
  },
  {
    path: "/livestream",
    name: "Live Stream",
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "livestream" */ "../views/LiveStream.vue")
      ),
  },
  {
    // Redirect any unknown routes to the Home page.
    path: "*",
    redirect: "/",
  },
];

const scrollBehavior = function (
  to: Route,
  from: Route,
  savedPosition?: void | Position
) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: scrollBehavior,
});

export default router;
