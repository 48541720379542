

















































































































import Vue from "vue";
import Countdown from "@/components/header/Countdown.vue";
import NavigationHeader from "@/components/header/NavigationHeader.vue";
import Footer from "@/components/footer/Footer.vue";

export default Vue.extend({
  name: "App",
  components: { Footer, NavigationHeader, Countdown },
  data: () => ({
    navDrawer: false,
  }),
  computed: {
    hasLiveStreamURL() {
      return (
        process.env.VUE_APP_LIVE_STREAM_URL !== undefined &&
        process.env.VUE_APP_LIVE_STREAM_URL.length > 0
      );
    },
  },
  methods: {
    onChangeNavDrawerState(value: boolean) {
      this.navDrawer = value;
    },
  },
});
